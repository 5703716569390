import React, { FC, ReactNode } from 'react'
import Title from './Title'
import classNames, { Argument as ClassValue } from 'classnames'

type PageTitleProps = {
  caption?: string
  heading: string
  title?: string
  updatePageTitle?: boolean
  extraClasses?: ClassValue
  additionalInlineComponent?: ReactNode
  containerExtraClasses?: ClassValue
  flexDirection?: 'row' | 'col'
}

const PageTitle: FC<PageTitleProps> = ({
  caption,
  heading,
  title,
  updatePageTitle = true,
  extraClasses,
  additionalInlineComponent = '',
  containerExtraClasses,
  flexDirection = 'row'
}) => {

  const hasTitle = title && title.trim().length > 0
  const hasCaption = caption && caption.trim().length > 0

  const pageCaption = hasCaption ? `- ${caption} ` : ''
  const pageTitle = hasTitle
    ? `${title} - APHIDS`
    : `${heading} ${pageCaption}- APHIDS`

  return (
    <>
      {caption && (
        <span data-testid="page-title-top-caption" className="govuk-caption-l">{caption}</span>
      )}

      <div data-testid="page-title-heading" className={`${classNames(containerExtraClasses)} flex-${flexDirection}`}>
        <h1 className={classNames('govuk-heading-l', extraClasses)}>
          {heading}
        </h1>
        {additionalInlineComponent}
      </div>

      {updatePageTitle && (
        <Title data-testid="page-title" title={pageTitle} />
      )}
    </>
  )
}

export default PageTitle