import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import CookieConsent from 'react-cookie-consent'

import { useAuthentication } from '../../hooks/authentication'
import PhaseBanner from '../PhaseBanner'
import FullViewHeader from './FullViewHeader'
import TabletViewHeader from './TabletViewHeader'
import { useAppSelector } from '../../store'

const Header: FC = () => {
  const { authenticated, tokenParsed } = useAuthentication()

  const {
    release_phase
  } = useAppSelector((state) => state.health)

  const isIdIssuer = tokenParsed?.groups.includes('aphids-id-issuer')

  return (
    <header>
      {authenticated && <CookieConsent
        expires={365}
        disableStyles={true}
        location="top"
        buttonText=""
        sameSite="strict"
        ariaAcceptLabel="I accept this website uses cookies"
        containerClasses="app-cookie-banner"
        contentClasses="app-cookie-banner__message"
        buttonClasses="app-cookie-banner__button hod-cross">
        The APHIDS system uses cookies to make the system simpler. <Link to={'/cookies'} target="_blank">Find out more about cookies</Link>
      </CookieConsent>}

      <FullViewHeader />
      {isIdIssuer && <TabletViewHeader />}

      {release_phase && <PhaseBanner phase={release_phase} displayFeedbackMessage={authenticated} />}
    </header>
  )
}

export default Header
