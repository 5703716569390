import React, { FC } from 'react'
import { Outlet } from 'react-router-dom'
import Header from './Header/Header'
import { SkipNavLink, SkipNavContent } from '@reach/skip-nav'

import Navigation from './Navigation'
import Footer from './Footer'
import MaintenanceBanner from './MaintenanceBanner'

type LayoutProps = {
  displayMenu?: boolean
}

const Layout: FC<LayoutProps> = ({
  displayMenu = true
}) => (
  <>
    <SkipNavLink className="govuk-skip-link">Skip to main content</SkipNavLink>

    <Header />

    {displayMenu && <Navigation />}

    <div className="app-pane__body">
      <div className="app-pane__content">
        <SkipNavContent />
        <main>
          <MaintenanceBanner />
          <div className="app-content" id="main-content">
            <Outlet />
          </div>
        </main>
      </div>
    </div>
    <Footer />
  </>
)

export default Layout
