import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import HoLogo from '../../icons/HoLogo'
import Logout from '../../modules/Authorisation/components/Logout'
import { useAuthentication } from '../../hooks/authentication'

const HOLogoWrapper = styled.div`
  width: 75px;
`

const UserInformationContainer = styled.div`
  text-align: center;
  padding-right: 24px;
`

const HeaderItems = styled.ul`
  margin: 0;
`

const HeaderItem = styled.li`
  vertical-align: middle;
`

const DisplayLabel = styled.span`
  display: block;
`

const TabletViewHeader: FC = () => {
  const { tokenParsed, authenticated } = useAuthentication()

  const username = tokenParsed ? (tokenParsed.preferred_username || 'user') : ''

  return (
    <div className="tablet-view-header" data-testid="outer-header-wrapper">
      <div className="wrapper-header__system tablet-view-header-wrapper" data-testid="header-wrapper">
        <HOLogoWrapper data-testid="ho-logo">
          <HoLogo width={150} height={35} title="Home Office" />
        </HOLogoWrapper>
          
        <Link to='/' className='header-name'>
          APHIDS
        </Link>
    
        {authenticated && (
          <div className='header-menu'>
            <HeaderItems>
              <HeaderItem><Logout /></HeaderItem>
            </HeaderItems>
          </div>)
        }
      </div>

      {authenticated && (
        <div className='header-menu right-align'>
          <HeaderItems>
            <HeaderItem>
              <UserInformationContainer>
                <DisplayLabel>{username}</DisplayLabel>
              </UserInformationContainer>
            </HeaderItem>
          </HeaderItems>
        </div>)
      }
    </div>
  )
}

export default TabletViewHeader
