import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import HoLogo from '../../icons/HoLogo'
import Logout from '../../modules/Authorisation/components/Logout'
import { useAuthentication } from '../../hooks/authentication'
import { useAppSelector } from '../../store'
import date from '../../services/date'

const UserInformationContainer = styled.div`
  text-align: center;
`

const HeaderItems = styled.ul`
  margin: 0;
`

const HeaderItem = styled.li`
  vertical-align: middle;
`

const DisplayLabel = styled.span`
  display: block;
`

const FullViewHeader: FC = () => {
  const { tokenParsed, authenticated } = useAuthentication()

  const {
    userProfile
  } = useAppSelector((state) => state.user)

  const lastLogin = userProfile?.lastLogin
    ? <DisplayLabel>Last login: {date.formatDateTimeFromUtcString(userProfile?.lastLogin)}</DisplayLabel>
    : <></>

  const username = tokenParsed ? (tokenParsed.preferred_username || 'user') : ''

  const wrapperStyle = tokenParsed?.groups.includes('aphids-id-issuer') ? 'full-view-header' : ''

  return (
    <div className={`wrapper-header__system ${wrapperStyle}`} data-testid="header-wrapper">
      <div className="header-logo" style={{ marginLeft: 0 }} data-testid="ho-logo">
        <HoLogo width={150} height={35} title="Home Office" />
      </div>
      <div className='header-title'>
        <Link to='/' className='header-name'>
          APHIDS
          <br />Access Pass Holder Information Distribution System
        </Link>
      </div>
      {authenticated && (
        <div className='header-menu'>
          <HeaderItems>
            <HeaderItem>
              <UserInformationContainer>
                <DisplayLabel>{username}</DisplayLabel>{lastLogin}
              </UserInformationContainer>
            </HeaderItem>
            <HeaderItem><Logout /></HeaderItem>
          </HeaderItems>
        </div>)
      }
    </div>
  )
}

export default FullViewHeader
